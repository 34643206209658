import axios from '@/plugins/axios'

export default {

  async buscar () {
    return await axios.get('alertas')
  },

  async buscarAlerta (id) {
    if(id!=0){
      return await axios.get(`alertas/${id}`)
    }else{
      return await axios.get(`alertas`)[0]
    }
  },
  
  async listarAlertasAssumidos (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`alertas/recebidos?${parametros}`)
  },

  async receber (id, dados) {
    return await axios.post(`alertas/${id}/receber`, dados)
  },

  async responder (id, dados) {
    return await axios.post(`alertas/${id}/responder`, dados)
  },

  async historico (id) {
    return await axios.get(`alertas/${id}/historico`)
  },

  async exportar (id) {
    return await axios.get(`alertas/${id}/exportar`)
  },

  async recusar (id) {
    return await axios.post(`alertas/${id}/recusar`)
  },

}
