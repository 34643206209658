<template>
  <dialog-alarme
    v-if="mostrarAlarme.mostrar"
    v-model="mostrarAlarme"
    :alarme="alarme"
  />
</template>

<script>
  import alertasApi from '@/api/alertas'

  export default {
    components: {
      DialogAlarme: () => import('@/components/dialog/alarme/DialogAlarme'),
    },

    data () {
      return {
        intervalo: 10000,
        som: new Audio(require('@/assets/sons/alerta.mp3')),
        timeout: null,
        alarme: { id: 0 },
        mostrarAlarme: {
          mostrar:false,
          assumido:false,
          assinado:false,
        },
      }
    },

    watch: {
      mostrarAlarme (val) {
        if (val.assumido) {
          if (!this.som.paused) {
            this.som.pause()
          }
        }
        if (val.assinado){
          this.fechar()
        }
      },
    },

    mounted () {
      if (process.env.VUE_APP_BUSCAR_ALERTAS === '0') {
        return
      }

      this.buscarAlertas()
    },

    beforeDestroy () {
      clearTimeout(this.timeout)
    },

    methods: {
      async buscarAlertas () {
        try {
          if (!this.$store.state.usuario.token || this.mostrarAlarme.mostrar === true) {
            return
          }

          const resposta = await alertasApi.buscar()

          if (resposta.data.length <= 0) {
            return
          }

          this.alarme = resposta.data[0]
          this.abrir()
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Erro ao buscar os alarmes' })
        } finally {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => this.buscarAlertas(), this.intervalo)
        }
      },

      abrir () {
        this.mostrarAlarme = {mostrar:true,assumido:false,assinado:false}
        this.som.play()
        this.som.loop = true
      },

      fechar () {
        this.som.pause()
        this.som.currentTime = 0
        this.alarme = null
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.buscarAlertas(), this.intervalo)
      },
    },
  }
</script>
